import React from 'react';

import { Title } from '../../components/Texts.js';
import ShowList from '../../containers/ShowList.js';
import ShowBlock from '../../containers/ShowBlock';

import BackgroundContainer from '../../containers/BackgroundContainer';
import scene from '../../media/scene8.jpg';

function Leadership(props) {

  // these data may need to stay here instead of JSON since we are making use of component tags directly
  const projects=[
    {
      name: 'Leadership at Clearbox Systems Pty Ltd.',
      descs: [
        'Led and mentored 2 interns from UTS who started at Clearbox Systems Pty Ltd and taught them coding practices aswell as Agile development practices.',
        'Organised meetings with Engineering teams aswell as the CTO, Head of Engineering and Software Architect in the business to document and acquire knowledge to complete development tasks',
        'Worked with Engineering Teams to meet software release deadlines and organised/created critical documentation such as Software Version Descriptions (SVDs) and installation procedures'
      ],
      year: '',
      keywords: [],
      link: '',
      source: '',
    },
    {
      name: 'Leadership at UTS',
      descs: [
        
        'Taught in the Faculty of Engineering which involved running 2hr-3hr sessions which would involve lecturing at times and assisting groups in finding answers for their problems',
        'Regularly asked and listened to student feedback to improve subject content and learning',
        'Commenced a role as a tutor at UTS Housing in 2020 which aimed to teach interested students Programming Fundamentals and Web Design'
      ],
      year: '',
      keywords: [],
      link: '',
      source: '',
    },
    {
      name: 'Leadership in Projects',
      descs: [
        'When completing group work either at University or in my professional career, I find myself taking on responsibility in whatever role or job I am asked to do.',
        'I have led multiple presentations in my time at University and organised meetings and put my name forward to be the team leader / scrum master in some subjects involving software development',
        'I am currently leading a project in my workplace and I manage / organise and prioritise the requirements from the backlog after discussion with the engineering team and Head of Software Development.'
      ],
      year: '',
      keywords: [],
      link: '',
      source: '',
    }
  ]

  return (
    <BackgroundContainer {...props} bg={scene} bgSize={"cover"} backgroundPosition={"center"}>
    <ShowBlock bg='bgCover2'>
      <Title>Leadership</Title>
      <ShowList rows={projects} collapseTitle='Techs'/>
    </ShowBlock>
    </BackgroundContainer>
  )
}

export default Leadership;