import React, {useRef} from 'react';
import { useHistory } from "react-router-dom";

import { Box } from '@chakra-ui/core';

import NavigationBar from '../containers/NavigationBar.js';
import Experiences from './Home/Experiences.js';
import Contact from './global/Contact.js';
import Leadership from './Home/Leadership.js';

function LeadershipP() {
  const ref = {
    "Landing": useRef(null),
    "Profile": useRef(null),
    "Education": useRef(null),
    "Skills": useRef(null),
    "Works": useRef(null),
    "Research": useRef(null),
    "Projects": useRef(null),
    "Experiences": useRef(null),
    "Honors": useRef(null),
    "Contact": useRef(null),
  }

  const routes = {
    'Home': { pathname: '/home' },
    'Academic Experience + Projects': { pathname: '/academic-experiences-and-projects' },
    'Professional Experience': { pathname: '/professional-experiences' },
    'Research': { pathname: '/research' },
    'Leadership': { pathname: '/leadership' }
  }

  const history = useHistory()

  const navClick = (compName) => {
    if (routes[compName] === undefined) {
      window.scrollTo(0, ref[compName].current.offsetTop - 64) 
    } else {
      history.push(routes[compName]);
    }
  }

  return (
    <Box bg='matte.bg' color='white' bgRepeat="no-repeat" bgSize='100% 100%'> 
    <NavigationBar navClick={navClick} names={['Home', 'Academic Experience + Projects', 'Professional Experience', 'Research', 'Leadership', 'Contact']}/>
    <hr/>
    <div ref={ref['Leadership']}></div>
    <Leadership/>
    <hr/>
    <div ref={ref['Extracurricular Experiences']}></div>
    <Experiences/>
    <hr/>
    <div ref={ref['Contact']}></div>
    <Contact/>
    </Box>
  );
}

export default LeadershipP;
