import React from 'react';

import {Flex, Box, Text} from "@chakra-ui/core";
import clearbox_logo from './media/clearbox_logo-transparent.png';
import agriwebb_logo from './media/agriwebb-logo-transparent.png';
import uts_logo from './media/uts_logo.png';
import { BulletPoint } from '../../containers/BlogPage.js';
import { Title, TextSmall, TextMedium } from '../../components/Texts.js';
import ShowList from '../../containers/ShowList.js';
import ShowBlock from '../../containers/ShowBlock.js';
import BackgroundContainer from '../../containers/BackgroundContainer';
import scene from '../../media/scene6.jpg';

function ProfExperiences(props) {
  const JobText = (props) => {
    return (<Flex direction={'column'}>
      <Text fontWeight='bold'>{props.title}</Text>
      <Flex>
        <TextSmall fontWeight='semibold'>{props.company}</TextSmall>
        <TextSmall>
        &nbsp;&nbsp;•&nbsp;&nbsp;
        </TextSmall>
        <TextSmall>{props.location}</TextSmall>
      </Flex>
    </Flex>)
  }

  // these data may need to stay here instead of JSON since we are making use of component tags directly
  const experiences=[
    {
      logo: clearbox_logo,
      name: (<JobText title='Software Developer' company='Clearbox Systems Pty Ltd.' location='Macquarie Park, Sydney, NSW, Australia'></JobText>),
      descs: [
        (
          <Box>
            <BulletPoint>Significantly reduced the time of API calls in the application by optimizing database queries with table aggregation and database triggers. The most notable performance increase was with a database query being reduced from 30 seconds to less than 100 milliseconds for a query.</BulletPoint>
            <BulletPoint>Championed test processes in CI/CD pipeline to enable a smoother Agile Development process and increase efficiency in the development team. This saw a higher increase in the story points completed each sprints and issues moving smoother from lane to lane on the Agile scrum board</BulletPoint>
            <BulletPoint>Reduced the time taken between installations by improving the .install4j installer and reducing manual config changes required in production. This reduced the installation process from taking up to 3 hours to less than 10 minutes.</BulletPoint>
            <BulletPoint>Developed complex plugins between different external applications for our client to be able to use the external application from our application</BulletPoint>
            <BulletPoint>Led a project to success and completed 40% of the tasks allocated for the project which included over 400 Jira issues.</BulletPoint>
            <BulletPoint>Worked accross the development and engineering teams to understand, design and plan technical detail about what configuration needed to be implemented for different devices such as modems, switches, satellites and ground stations.</BulletPoint>
            <BulletPoint>Reduced the amount of bugs in the application to increase reliability and performance.</BulletPoint>
          </Box>
        )
      ],
      year: 'June 2021 - Currently Employed',
      link: 'https://clearboxsystems.com.au/',
    },
    {
      logo: agriwebb_logo,
      name: (<JobText title='Software Engineering Intern' company='AgriWebb' location='Surry Hills, Sydney, NSW, Australia'></JobText>),
      descs: [
        (
          <Box>
            <BulletPoint>Developed SQL and AWS Lambda queries to extract data for the Supply Chain Management Dashbord</BulletPoint>
            <BulletPoint>Developed a system feature to plot real estate listings from the RESTful API data</BulletPoint>
            <BulletPoint>Designed the routing between different components of the supply chain management dashboard such as the interaction between the view, the Snowflake data, and the KNN algorithm</BulletPoint>
            <BulletPoint>Organised peer coding sessions and went through code reviews with peers in the team to assist in completing their tasks</BulletPoint>
            <BulletPoint>Participated in the presentation of the work we completed for the project and explained the design of the system and key components</BulletPoint>
          </Box>
        )
      ],
      year: 'Feb 2022 - June 2022',
      keywords: ['Backend', 'Distributed Systems', 'Scalability', 'CI/CD', 'Play Framework', 'Scala', 'Kafka', 'SQL', 'Mockito', 'Cucumber', 'Ajax', 'HTML', 'TeamCity', 'Docker', 'Grafana', 'Git'],
      link: 'https://www.agriwebb.com/',
    },
    {
      logo: uts_logo,
      name: (<JobText title='University Lecturer and Tutor' company='University of Technology Sydney' location='Ultimo, Sydney, NSW, Australia'></JobText>),
      descs: [
        (
          <Box>
            <BulletPoint>Lectured 2 subjects and prepared material and resources for them</BulletPoint>
            <BulletPoint>Tutored 10 different classes over two semesters</BulletPoint>
            <BulletPoint>Marked for Database Fundamentals subject and assisted in answering questions during lectures</BulletPoint>
            <BulletPoint>Completed part time study and working at Clearbox Systems Pty Ltd. while teaching and achieving exceptional results</BulletPoint>
            <BulletPoint>Recorded online video resources for students to assist in projects</BulletPoint>
            <BulletPoint>Tutored 4 technical subjects (Software Engineering Studio, Data Structures & Algorithms, Applications Programming, Programming Fundamentals)</BulletPoint>
            <BulletPoint>Taught advanced and complex technical knowledge about data structures and algorithms</BulletPoint>
            <BulletPoint>Taught Agile processes and good coding practices using Git</BulletPoint>
            <BulletPoint>Taught both advanced and entry-level programming using Java, C++, Python and technologies such as NodeJs, React, Angular and other common frameworks.</BulletPoint>
          </Box>
        )
      ],
      year: 'Feb 2021 - Nov 2021',
      keywords: ['Backend', 'Distributed Systems', 'Scalability', 'CI/CD', 'Play Framework', 'Scala', 'Kafka', 'SQL', 'Mockito', 'Cucumber', 'Ajax', 'HTML', 'TeamCity', 'Docker', 'Grafana', 'Git'],
      link: 'https://www.uts.edu.au/',
    }
  ]

  return (
    <BackgroundContainer {...props} bg={scene} bgSize={"cover"} backgroundPosition={"center"}>
    <ShowBlock bg='bgCover2'>
      <Title>Professional Experiences</Title>
      <TextMedium letterSpacing='widest' textAlign='center' color='black.400' mx='10px' w='1200px' wrap='wrap'>
        During the past 5 years, I have worked majorily at Clearbox Systems Pty Ltd, but have also been involved tutoring for 
        the University for 4 different subjects and completed an internship at AgriWebb helping develop an analytics dashboard for Supply Chain Management (SCM).
      </TextMedium>
      <ShowList rows={experiences} collapseTitle='Experiences'/>
    </ShowBlock>
    </BackgroundContainer>
  )
}

export default ProfExperiences;