import React from 'react';
import { Button, Box, Flex, Image, Grid, Collapse, PseudoBox } from "@chakra-ui/core";

import uts_logo from './media/uts_logo.png';
import scas_logo from './media/scas_logo.png';
import study_logo from './media/self-study-transparent.png';

import scene from '../../media/scene3.jpg';

import { Title, TextBig, TextMedium } from '../../components/Texts.js';
import ShowBlock from '../../containers/ShowBlock';
import BackgroundContainer from '../../containers/BackgroundContainer';

/*
NOTE:

TEMPLATE/STRUCTURE OF THIS BLOCK CONTAINER FILE IS THE OLD VERSION, 
WHICH IS MORE UNORGANIZED COMPARED TO OTHER BLOCK CONTAINER FILES.

SINCE VISUAL CHANGES AND REUSAGE IS NOT FREQUENT, 
STRUCTURAL REVISIONING IS NOT EXPECTED IN THE MEANTIME.
*/

function Education(props) {
  const schools=[
    {
      name: 'University of Technology Sydney',
      color: 'green.100',
      desc: 'B. of Engineering (Majoring in Software Engineering)',
      subdescs: [ 'Data Analytics and AI Sub-Major', 'Current GPA: 6.47 / 7.0', 'Current WAM: 87.44 / 100', 'All marks are Distinction or High Distinction Mark' ],
      year: 'February 2019 - November 2023',
      courses: ['100/100 - Software Engineering Studio 1B', '99/100 - Machine Learning', '99/100 - Data Structures and Algorithms',
               '99/100 - Applications Programming', '99/100 Programming Fundamentals'],
      logo: uts_logo
    },
    {
      name: 'St Columba Anglican School',
      color: 'blue.200',
      desc: 'Higher School Certificate - (Yr11 - Yr12)',
      subdescs: [''],
      year: '2016 - 2017',
      courses: ['Band 6 - Software Design and Development (Final Mark - 95/100)', 'Band 6 - Music' ],
      logo: scas_logo
    },
    {
    name: 'Self-Study',
      color: 'pink.100',
      desc: 'The courses below are completed without guidance from formal institutions',
      subdescs: [''],
      year: '',
      courses: [
        'Introduction to JavaScript, Codecademy, 2020', 
        'Introduction to Java, Codecademy, 2020', 
        'Introduction to Python, Codecademy, 2020', 
        'Intermediate Python, Codecademy, 2020', 
        'Advanced Python, Codecademy, 2020', 
        'Machine Learning, by Brilliant, 2021',
        'Docker and Kurbenetes Course, VMWare, 2022',
        'Amazon Web Services (AWS) Course, by Amazon, 2022'
      ],
      logo: study_logo
    }
  ]

  const [show, setShow] = React.useState(Array(schools.length).fill(false));        // collapsible course list state management
  const handleToggle = (idx) => {
    let nshow = Array.from(show)
    nshow[idx] = !nshow[idx]
    setShow(nshow)
  }

  return (
    <BackgroundContainer bg={scene} backgroundPosition='center' {...props}>
    <ShowBlock bg='bgCover4'>
      <Title mb='36px'>Education</Title>
      {
        schools.map((school, idx) => {
          const {name, color, desc, subdescs, year, courses, logo} = school
          return (<Grid templateColumns={{ base: "1fr", md: "auto auto"}} columnGap='0px' pt='1.5rem' px='20px' w='100%' maxW='1200px'>
            <Flex>
              {
                logo === 'n/a'?
                (<Box h='100px' w='100px' pr='10px'></Box>):
                (<PseudoBox transition="all 0.2s cubic-bezier(.08,.52,.52,1)" _hover={{ transform: 'scale(1.05)'}}><Image src={logo} alt='logo' size='100px' objectFit='contain' pr='10px'/></PseudoBox>)
              }
              <Flex direction='column'>
                <TextBig color={color} fontWeight='bold'>
                  {name}
                </TextBig>
                <TextBig>
                  {desc}
                </TextBig>
                
                {
                  subdescs.map(
                    (subdesc) => (
                      <TextMedium>
                        {subdesc}
                      </TextMedium>
                    )
                  )
                }
                
                {
                  courses !== undefined && courses !== null?
                  (<Box><Button
                    variant='ghost' color='white'
                    _hover={{bg: '#FFFFFF10'}} rounded='3px'
                    _active={{ transform: 'scale(0.9)'}}
                    _focus={{ boxShadow: '0 0 0 0'}}
                    rightIcon="chevron-down"
                    w='fit-content'
                    pl='0'
                    pr='2'
                    onClick={() => handleToggle(idx)}
                    fontWeight='normal' letterSpacing='wide'
                  >
                    Highlighted Courses
                  </Button>
                  <Collapse mt={1} isOpen={show[idx]}>
                    {courses.map(course => (
                      <TextMedium><li>{course}</li></TextMedium>
                    ))}
                  </Collapse></Box>):
                  (<Box h='0' w='0'></Box>)
                }
                
              </Flex>
            </Flex>
            <TextBig pl='20px' textAlign='right'>
              {year}
            </TextBig>
          </Grid>)
        })
      }
    </ShowBlock></BackgroundContainer>
  )
}

export default Education;