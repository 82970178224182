import React from 'react';
import { Flex, PseudoBox } from "@chakra-ui/core";
import { FaFileDownload } from "react-icons/fa";

import { Title, TextMedium } from '../../components/Texts.js';
import transcriptPDF from '../../files/Student-Record.pdf';

function Transcript(props) {
  return (
    <Flex w='100%' pb='3rem' pt='1rem' direction='column' {...props}>
      <Title>Transcript</Title>
      <Flex mx='20px' direction='column'>
        <TextMedium textAlign='center' flexWrap='wrap'>
          Please find a download link to my Academic Transcript below. 
        </TextMedium>
        <br/>
        <a href={ transcriptPDF } download="Academic Transcript - Mitchell Treeves.pdf">
          <Flex justifyContent='center' alignItems='center' wrap='wrap'>
            <TextMedium textAlign='center'>
              Download Academic Transcript
            </TextMedium>
            <PseudoBox as={FaFileDownload} size='30px' mx='10px' transition="all 0.2s cubic-bezier(.08,.52,.52,1)" _hover={{ transform: 'scale(1.2)'}}/>
          </Flex>
        </a>
      </Flex>
    </Flex>
  )
}

export default Transcript