import React from 'react';
import { Box, Flex, Image, Grid } from "@chakra-ui/core";
import portrait from './media/portrait-rep.jpg';

import { Title, TextMedium } from '../../components/Texts.js';
import ShowBlock from '../../containers/ShowBlock.js';
import BackgroundContainer from '../../containers/BackgroundContainer';
import scene from '../../media/scene2-s2.jpg';

// might componentize this image-paragraphs template soon
function Profile(props) {
  return (
    <BackgroundContainer bg={scene} {...props}>
    <ShowBlock bg='bgCover4'>
      <Title>Hi There!</Title>
      <Grid templateColumns={{ base: "1fr", md: "minmax(0px, 150px) minmax(300px, auto) minmax(300px, auto) minmax(0px, 150px)"}} columnGap='3.5rem'>
        <Box></Box>
        <Flex justifyContent='center'pt='1.5rem' mx='20px'>
          <Image src={portrait} alt='Portrait' width='500px' height='667px' objectFit='cover' borderRadius='4px' boxShadow='2xl'/>
        </Flex>
        <Flex justifyContent='center' marginTop='-0.5rem' pt='1.5rem' mx='20px'>
          <Flex maxW='500px' wrap='wrap' direction='column'>
            <TextMedium>
              My name is Mitchell Treeves.
              But I usually let people call me <span style={{color: '#63B3ED', fontWeight:'bold'}}>Mitch</span> for short.<br/>
            </TextMedium>
            <TextMedium mt='1rem'>
              <span style={{color: '#63B3ED', fontWeight:'bold'}}>treevesy</span> is my alias codename which I like to use online, originating from my last name with a <b>'y'</b> at the end.
            </TextMedium>
            <TextMedium mt='1rem'>
              I grew up in a small town in NSW Australia called Port Macquarie. 
              However, I am currently living and working in Sydney now.
              I completed my studies achieving First Class Honours with a Bachelors of Engineering at the University of Technology Sydney.
              For my degree I majored in Software Engineering with a sub-major in Data Analytics and AI.
            </TextMedium>
            <TextMedium mt='1rem'>
              What led me into the computing and the digital world was my knack for solving problems. Ever since I was young I have always loved
              learning, solving problems and the satisfaction of achieving goals. This can be seen by my professional experience as a Lecturer, Tutor and 
              Casual Academic at UTS aswell as in my professional practice as a Software Developer. I aim high to achieve above exceptional results
              and strive towards tasks with a competitive nature in the work that I do. I also have the ability to lead and work accross different teams as I 
              have done at University and in my curent role as a Software Developer at Clearbox Systems Pty Ltd.
            </TextMedium>
            <TextMedium mt='1rem'>
              Apart from experience and technical knowledge, I have also developed proficiency in a range of soft skills througout my academic and professional 
              pursuits, some including leadership, communicating, and more listed below...
            </TextMedium>
          </Flex>
        </Flex>
        <Box></Box>
      </Grid>
    </ShowBlock></BackgroundContainer>
  )
}

export default Profile;