import React from 'react';
import {Flex, Image} from "@chakra-ui/core";

import homefinder from './media/homefinder.jpg';
import lipreader from './media/lipreading.gif';
import agriwebb from './media/agriwebb-mockup.jpg';
import ecograf from './media/ecograf.jpg';

import { Title } from '../../components/Texts.js';
import ShowList from '../../containers/ShowList.js';
import ShowBlock from '../../containers/ShowBlock';

import BackgroundContainer from '../../containers/BackgroundContainer';
import scene from '../../media/scene8.jpg';

function Projects(props) {

  // these data may need to stay here instead of JSON since we are making use of component tags directly
  const projects=[
    {
      name: 'Personal Portfolio Website',
      descs: ['The website you are currently viewing!'],
      year: 'Since Sep 2023',
      keywords: ['Frontend', 'React', 'JavaScript', 'HTML', 'CSS', 'Chakra UI'],
      link: 'https://to-be-done.com',
      source: 'https://gitlab.com/treevesy/personal-website',
    },
    {
      name: 'HomeFinder',
      descs: ['The HomeFinder application was a web app that allowed users to find a house listing off realestate.com, domain.com and other house listing websites.',
            ' - Led the design and architecture of the application to help plan and implement the interaction between data and the view for the user.',
            ' - Assisted in programming the Backend using MySQL and the Frontend using ReactJs to display the listings on a map and use an AI model in the backend to display relevant listings based off the users survey.'
      ],
      year: 'Feb 2022 - May 2022',
      keywords: ['Backend', 'Frontend', 'NodeJs', 'JavaScript', 'HTML', 'CSS', 'React', 'Microsoft Azure SQL Database',  
                'Python', 'Docker', 'KNN Algorithm', 'Google Maps API', 'Google Cloud' ],
      link: 'https://to-be-done/',
      source: 'https://github.com/treevesy/to-be-done',
      extras: (<Flex justifyContent='center' mt='1rem' wrap='wrap'>
                <Image src={homefinder} alt='screenshot' h='300px' m='10px' borderRadius='4px' boxShadow='xl'/>
              </Flex>)
    },
    {
      name: 'AI Lip Reader',
      descs: [
        'The AI Lip Reader was a project where I was in a small team of 3 data scientists (including myself) called the Linguistic Learners.',
        'I trained and developed a Temporal Convolutional Neural Network which detected face landmarks and extracted Regions of Interest (ROIs) and used these ROIs to infer the words coming from a speakers mouth without audio.',
        'I also led the development of a DJango application which exposed this AI model so users could upload a video and receive annotations, or, do live video and get the live web-cam annotated.'
      ],
      year: 'Apr 2021 - Apr 2022',
      keywords: ['Backend', 'Frontend', 'DJango', 'Pytorch', 'MongoDB', 'JavaScript', 'HTML', 'CSS'],
      link: 'https://to-be-done/',
      source: 'https://github.com/treevesy/to-be-done',
      extras: (<Flex justifyContent='center' mt='1rem' wrap='wrap'>
                <Image src={lipreader} alt='screenshot' h='300px' m='10px' borderRadius='4px' boxShadow='xl'/>
              </Flex>)
    },
    {
      name: 'AgriWebb - Supply Chain Management Dashboard',
      descs: [
        'The AgriWebb Supply Chain Management Dashboard was a dashboard I assisted in developing which involved a SQL Snowflake Database and displaying this in an Angular dashboard.',
        'I was involved in exploring the entity relationship data and hooking up the API calls between the Angular application and the Snowflake database using AWS  Lambda queries.'
      ],
      year: 'Jul 2020 - Dec 2020',
      keywords: ['Backend', 'Frontend', 'Angular', 'Machine Learning', 'APIs', 'Amazon Web Services', 'Snowflake SQL', 'JavaScript', 'Python'],
      link: '#/pages/GFC',
      source: 'https://to-be-done',
      extras: (<Flex justifyContent='center' mt='1rem' wrap='wrap'>
                <Image src={agriwebb} alt='screenshot' h='300px' m='10px' borderRadius='4px' boxShadow='xl'/>
              </Flex>)
    },
    {
      name: 'EcoGraf Financial Evaluation Project',
      descs: [
        'The EcoGraf Financial Evaluation project involved developing a forecast of a developing project for EcoGraf.',
        'I developed expense reports, income statements, balance sheets and cash flow statements to forecast the outcome of the project for the business in 10 years time.'
      ],
      year: 'Jun 2019 - Jan 2020',
      keywords: ['Leading', 'Financial Evaluation', 'Financial Reports', 'Documentation'],
      link: 'https://to-be-done',
      extras: (<Flex justifyContent='center' mt='1rem' wrap='wrap'>
                <Image src={ecograf} alt='screenshot' h='250px' m='10px' borderRadius='4px' boxShadow='xl'/>
              </Flex>)
    }
  ]

  return (
    <BackgroundContainer {...props} bg={scene} bgSize={"cover"} backgroundPosition={"center"}>
    <ShowBlock bg='bgCover2'>
      <Title>Projects</Title>
      <ShowList rows={projects} collapseTitle='Techs'/>
    </ShowBlock>
    </BackgroundContainer>
  )
}

export default Projects;