import React from 'react';
import {Flex, Image} from "@chakra-ui/core";

import scas_logo from './media/scas_logo.png';
import uts_logo from './media/uts_logo.png';

import { Title } from '../../components/Texts.js';
import ShowList from '../../containers/ShowList.js';
import ShowBlock from '../../containers/ShowBlock';

import BackgroundContainer from '../../containers/BackgroundContainer';
import scene from '../../media/scene10.jpg';

function Honors(props) {
  const gold = 'yellow.400'
  // const silver = 'gray.300'
  // const bronze = 'orange.200'
  const LogoYearCombo = (props) => {
    return (<Flex justifyContent='flex-end' mt='1rem' wrap='wrap'>
      {props.children}
    </Flex>)
  }

  // these data may need to stay here instead of JSON since we are making use of component tags directly
  const honors=[
    {
      name: 'Deans List, UTS',
      color: gold,
      descs: ['Continuously listed on the Deans list for my high academic achievements at University'],
      year: (<LogoYearCombo>
              <Image src={uts_logo} alt='screenshot' h='80px' m='10px' mx='30px'/>
              2019, 2020, 2022
            </LogoYearCombo>),
      keywords: ['Deans List'],
      link: 'https://www.uts.edu.au/current-students/current-students-information-faculty-engineering-and-it/scholarships-and-prizes',
    },
    {
      name: '1st Place Software Design and Development (HSC)',
      color: gold,
      descs: ['Won the award in HSC for the highest mark for Software Design and Development'],
      year: (<LogoYearCombo>
              <Image src={scas_logo} alt='screenshot' h='80px' m='10px'/>
              2017
            </LogoYearCombo>),
      keywords: [],
      link: '',
    }
  ]

  return (
    <BackgroundContainer {...props} bg={scene} bgSize={"cover"} backgroundPosition={"bottom center"}>
    <ShowBlock bg='bgCover2'>
      <Title>Awards and Honors</Title>
      <ShowList rows={honors} collapseTitle='Aspects'/>
    </ShowBlock>
    </BackgroundContainer>
  )
}

export default Honors;