import React from 'react';

import { Title } from '../../components/Texts.js';
import ShowList from '../../containers/ShowList.js';
import ShowBlock from '../../containers/ShowBlock.js';
import BackgroundContainer from '../../containers/BackgroundContainer';
import scene from '../../media/scene9.jpg';

function Experiences(props) {
  // these data may need to stay here instead of JSON since we are making use of component tags directly
  const experiences=[
    {
      name: 'Participant, HackaSat 2022',
      descs: [
        'Solved 2 difficult HackaSat problems, one involving API routes to control a spaceship that had to use lazers to destroy asteroids in space',
        'Our team managed to place in the top 50 teams in HackaSat which was a great achievement'
      ],
      year: 'Jul 2022',
      keywords: ['Team Leadership', 'Business', 'Hacking', 'HackaSat'],
      link: 'https://hackasat.com/',
    },
    {
      name: 'Member, UTS Engineering Society',
      descs: ['Assisted in event planning and development of learning opportunities in the UTS Engineering Society.'],
      year: 'Feb 2021 - Nov 2022',
      keywords: ['Learning', 'Leader'],
      link: 'https://utsengsoc.com/',
    }
  ]

  return (
    <BackgroundContainer {...props} bg={scene} bgSize={"cover"} backgroundPosition={"center"}>
    <ShowBlock bg='bgCover4'>
      <Title>Extracurricular Experiences</Title>
      <ShowList rows={experiences} collapseTitle='Experiences'/>
    </ShowBlock>
    </BackgroundContainer>
  )
}

export default Experiences;