import React, {useRef} from 'react';
import { useHistory } from "react-router-dom";

import { Box } from '@chakra-ui/core'

import NavigationBar from '../containers/NavigationBar.js';
import Education from './Home/Education.js';
import Projects from './Home/Projects.js';
import Contact from './global/Contact.js';
import Transcript from './global/Transcript.js';
import Honors from './Home/Honors.js';

function AcademicExperiencesAndProjects() {
  const ref = {
    "Education": useRef(null),
    "Projects": useRef(null),
    "Contact": useRef(null),
  }

  const history = useHistory()

  // const routes = {
  //   'Landing': { pathname: '/home' },
  //   'Profile': { pathname: '/home', state: { scrollTo: 'Profile' } },
  //   'Skills': { pathname: '/home', state: { scrollTo: 'Skills' } },
  //   // 'Education': { '/home', { scrollTo: 'education' } },
  //   'Academic Experience and Projects': { pathname: '/academic-experiences-and-projects' },
  //   'Professional Experience': { pathname: '/professional-experience' },
  //   'Research': { pathname: '/research' },
  //   'Leadership': { pathname: '/leadership' }
  // }

  const routes = {
    'Home': { pathname: '/home' },
    'Academic Experience + Projects': { pathname: '/academic-experiences-and-projects' },
    'Professional Experience': { pathname: '/professional-experiences' },
    'Research': { pathname: '/research' },
    'Leadership': { pathname: '/leadership' }
  }

  const navClick = (compName) => {
    if (routes[compName] === undefined) {
      window.scrollTo(0, ref[compName].current.offsetTop - 64) 
    } else {
      history.push(routes[compName]);
    }
  }

  /* <NavigationBar navClick={navClick} names={['Home', 'Profile', 'Skills', 'Education', 'Projects', 'Professional Experience', 'Works', 'Honors', 'Research', 'Leadership', 'Contact']}/> */
  
  return (
    <Box bg='matte.bg' color='white' bgRepeat="no-repeat" bgSize='100% 100%'> 
      <NavigationBar navClick={navClick} names={['Home', 'Academic Experience + Projects', 'Professional Experience', 'Research', 'Leadership', 'Contact']}/>
      <hr/>
      <div ref={ref['Education']}></div>
      <Education/>
      <hr/>
      <div ref={ref['Transcript']}></div>
      <Transcript/>
      <hr/>
      <div ref={ref['Projects']}></div>
      <Projects/>
      <hr/>
      <div ref={ref['Honors']}></div>
      <Honors/>
      <hr/>
      <div ref={ref['Contact']}></div>
      <Contact/>
    </Box>
  );
}

export default AcademicExperiencesAndProjects;
