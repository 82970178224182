import React from 'react';
import './App.css';

import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import customTheme from './CustomTheme.js'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'

import Home from './pages/Home.js';
import AcademicExperiencesAndProjects from './pages/AcademicExperiencesAndProjects';
import ProfessionalExperiences from './pages/ProfessionalExperiences';
import Research from './pages/Research';
import LeadershipP from './pages/LeadershipP';
function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/academic-experiences-and-projects" component={AcademicExperiencesAndProjects}/>
          <Route exact path="/professional-experiences" component={ProfessionalExperiences}/>
          <Route exact path="/research" component={Research}/>
          <Route exact path="/leadership" component={LeadershipP}/>
          <Route exact path={["/", "//"]}><Redirect to="/home"/></Route>
          <Route component={() => (<span>Page Not Found</span>)}/>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
