import React from 'react';

import { TextBig, TextMedium, TextSmall, Title } from '../../components/Texts.js';
import bin_and_rubbish from './media/bin_and_rubbish_transparent.png';
import { BulletPoint } from '../../containers/BlogPage.js';
import { Flex, Box, Image } from "@chakra-ui/core";
import ShowList from '../../containers/ShowList.js';
import ShowBlock from '../../containers/ShowBlock.js';
import BackgroundContainer from '../../containers/BackgroundContainer';
import rubbish_scene from '../../media/rubbish-scene.jpg';
import picking_up_rubbish from './media/picking-up-rubbish.jpg';
import data_collection from './media/data-collection.jpg';
import aerial_data_collection from './media/aerial-data-collection.jpg';

function ResearchExperiences(props) {
  // these data may need to stay here instead of JSON since we are making use of component tags directly
  const experiences=[
    {
      logo: bin_and_rubbish,
      name: 'Feasibility of UAV Rubbish Detection Models in Australian Parklands using Domain Transfer',
      descs: [(<Box >
                <TextMedium mt='1rem'>Worked on the formal research with both supervisors Alen Alempijevic, Ph.D., and Raphael Guenot-Falque, Ph.D, at University of Technology Sydney.</TextMedium>
                <TextMedium mt='1rem'>The objective was to train object detection models to detect rubbish in urban and natural parklands using a mixture of datasets and different state of the art AI models using transfer learning.</TextMedium>
                <TextMedium mt='1rem'>Three datasets were used for the research including a public dataset known as UAV Vaste (Secondary data - can be found here - LINK), a dataset of collected Drone images from parks in Sydney (Primary data), syntheticly generated images of Australian wasteland using the data collected</TextMedium>
                <TextMedium mt='1rem'>The dataset uses Generative Adversarial Networks (GAN's) to create multiple objects in a scene at spatially distinct locations using a GAN provided in this repository - </TextMedium>
                <br/>
                <TextBig>Work Completed So Far</TextBig>
                <BulletPoint>Trained a YoloV5 and YoloV8 model on the UAV Vaste dataset and achieved a MaP50-95 score of 0.35 and a MaP50 score of 0.8</BulletPoint>
                <BulletPoint>Pre-processed and transformed the primary data collected to be trained by GAN and produce multi-instance synthesised waste images</BulletPoint>
                <BulletPoint>Trained and tuned the model of a certain GAN architecture using PyTorch to create the synthetic dataset</BulletPoint>
                <BulletPoint>To be continued...</BulletPoint>
              </Box>)
      ],
      year: 'Jan 2023 - Nov 2023',
      keywords: ['Research', 'Machine Learning', 'Conputer Vision', 'Generative Adversarial Networks', 'PyTorch', 'Python', 'YoloV8', 'GAN Network', 'EfficientDet Networks'],
      link: 'https://to-be-done',
      extras: (
                <Flex justifyContent='center' mt='1rem' wrap='wrap'>
                  <Box>
                    <Image src={aerial_data_collection} alt='screenshot' h='400px' m='10px' borderRadius='4px' boxShadow='xl'/>
                    <TextSmall textAlign='center'>Data being collected from drone (UAV)</TextSmall>
                  </Box>
                  <Box>
                    <Image src={picking_up_rubbish} alt='screenshot' h='400px' m='10px' borderRadius='4px' boxShadow='xl'/>
                    <TextSmall textAlign='center'>Collecting rubbish</TextSmall>
                  </Box>
                  <Box>
                    <Image src={data_collection} alt='screenshot' h='400px' m='10px' borderRadius='4px' boxShadow='xl'/>
                    <TextSmall textAlign='center'>Collecting instances of waste for data synthesisation</TextSmall>
                  </Box>
                </Flex>
      )
    }
  ]

  return (
    <BackgroundContainer {...props} bg={rubbish_scene} bgSize={"cover"} backgroundPosition={"center"}>
    <ShowBlock bg='bgCover4'>
      <Title>Research Experiences</Title>
      <ShowList rows={experiences} collapseTitle='Experiences'/>
    </ShowBlock>
    </BackgroundContainer>
  )
}

export default ResearchExperiences;