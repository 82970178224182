import React, {useRef} from 'react';
import { useHistory } from "react-router-dom";

import { Box } from '@chakra-ui/core'

import NavigationBar from '../containers/NavigationBar.js';
import Landing from './Home/Landing.js';
import Profile from './Home/Profile.js';
import Skills from './Home/Skills.js';
import Contact from './global/Contact.js';

import Resume from './global/Resume.js';
import Transcript from './global/Transcript.js';

function Home() {
  const ref = {
    "Landing": useRef(null),
    "Profile": useRef(null),
    "Education": useRef(null),
    "Skills": useRef(null),
    "Works": useRef(null),
    "Research": useRef(null),
    "Projects": useRef(null),
    "Experiences": useRef(null),
    "Honors": useRef(null),
    "Contact": useRef(null),
  }

  const routes = {
    'Home': { pathname: '/home' },
    'Academic Experience + Projects': { pathname: '/academic-experiences-and-projects' },
    'Professional Experience': { pathname: '/professional-experiences' },
    'Research': { pathname: '/research' },
    'Leadership': { pathname: '/leadership' }
  }

  const history = useHistory()

  const navClick = (compName) => {
    if (routes[compName] === undefined) {
      window.scrollTo(0, ref[compName].current.offsetTop - 64) 
    } else {
      history.push(routes[compName]);
    }
  }
  // const history = useHistory();
  // console.log(history);

  // const state = history['location']['state'];
  // if (state !== undefined) {
  //   const scrollTo = state['scrollTo'];
  //   if (scrollTo !== undefined) {
  //     window.scrollTo(0, ref[scrollTo].current.offsetTop - 64)
  //   }
  // }

  return (
    <Box bg='matte.bg' color='white' bgRepeat="no-repeat" bgSize='100% 100%'> 
      <NavigationBar navClick={navClick} names={['Home', 'Academic Experience + Projects', 'Professional Experience', 'Research', 'Leadership', 'Contact']}/>
      <hr/>
      <div ref={ref['Landing']}></div>
      <Landing/>
      <hr/>
      <div ref={ref['Profile']} id="profile"></div>
      <Profile/>
      <hr/>
      <div ref={ref['Skills']}></div>
      <Skills/>
      <hr/>
      <div ref={ref['Resume']}></div>
      <Resume/> 
      <hr/>
      <div ref={ref['Transcript']}></div>
      <Transcript/>
      <hr/>
      <div ref={ref['Contact']}></div>
      <Contact/>
    </Box>
  );
}

export default Home;
